<template>
  <RightSideBar
    v-if="openModal"
    @close="closeModal()"
    :loading="loading"
    @submit="save2FA"
    submit="Save"
    :button-class="`bg-dynamicBackBtn text-white rounded`"
    :disabled-button="disableBtn"
    :close-button="disableAction"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Two Factor Authentication
      </p>
    </template>
    <template v-slot:subtitle>
      <div class="w-full mb-3">
        <p class="font-semibold text-darkPurple my-4">
          Turn on two factor authentication for added protection to your
          account. As the root admin, you can also enforce this across your
          organization, thus every user has it turned on by default.
        </p>
        <checkbox
          :value="setUp ? false : true"
          checkbox-size="height:16px; width:16px; margin-top: 5px; margin-bottom: 10px;"
          label="2FA disabled for all users within the organization"
          disabled
          v-model="twoFADisabled"
          class="opacity-50"
        />
        <div class="mt-1 w-full flex" />
        <checkbox
          :value="enforceData"
          checkbox-size="height:16px; width:16px; margin-top: 5px; margin-bottom: 10px;"
          label="Enforce two factor authentication for all users within this organization."
          disabled
          v-model="enforceData"
          class="opacity-50"
        />
      </div>
    </template>
    <div>
      <div
        :class="[
          'flex flex-col items-start gap-3',
          visibility.first ? '' : 'opacity-50'
        ]"
      >
        <p class="text-darkPurple text-base">
          Require verification code each time you login or change sensitive
          information in your profile.
        </p>
        <p class="text-darkPurple text-sm">
          Start by entering your password below
        </p>
        <span class="text-red-500 mr-4" v-if="error.first"
          >Incorrect Password</span
        >
        <CText
          class="w-full"
          :variant="error.first ? 'error' : ''"
          placeholder="Password"
          :disabled="!visibility.first"
          v-model="password"
          :type="passwordText"
        />
        <Button
          @click="checkPassword"
          :disabled="!visibility.first"
          :class="[' flex', visibility.first ? 'text-flame' : 'text-jet']"
        >
          <span class="mr-24">
            Proceed
          </span>
        </Button>
        <checkbox
          label="Show Password"
          checkbox-size="width:16px; height: 16px; margin-top: 3%"
          class="flex justify-center items-center"
          v-model="encryptPassword"
        />
      </div>
      <divider class="  my-4" border-dash />
      <div
        :class="[
          'flex flex-col items-start gap-3',
          visibility.second ? '' : 'opacity-50'
        ]"
      >
        <p class="text-darkPurple text-base">
          Enable two-factor authentication
        </p>
        <RadioButton
          :options="options"
          col-span="col-span-4"
          class="ml-2 text-base text-darkPurple"
          row-gap="gap-x-5 "
          v-model="optionValue"
          space-between="mr-2"
          :disabled="!visibility.second || !$AuthUser.isRoot"
        />
        <small v-if="!$AuthUser.isRoot" class="text-flame text-xs font-bold">You must be a root user to perform this action</small>
      </div>
      <divider class="  my-4" border-dash />
      <div style="height:100% mt-8" v-if="resendLoader">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div class="my-4" v-if="error.second">
          <span class="text-red-500 mb-7 flex" style="justify-content: center;"
            >Error in resending Verification code
          </span>
          <Button
            @click="resendCode"
            :disabled="!visibility.second"
            :class="[
              'w-full mt-4 flex',
              visibility.second ? 'text-flame' : 'text-jet'
            ]"
            style="justify-content: center"
            width="15rem"
          >
            <span class="mr-4 w-full">
              Resend Verification Code
            </span>
          </Button>
        </div>

        <div v-else>
          <div class="flex flex-col items-start gap-3" v-if="visibility.third">
            <p class="text-darkPurple text-base">
              Verify Email
            </p>
            <p class="text-darkPurple text-sm">
              Enter the confirmation code send to specified email address to
              confirm ownership.
            </p>
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input mr-2 border-jet border"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="false"
              @on-complete="handleOnComplete"
            />
            <Button
              @click="resendCode"
              :disabled="!visibility.second"
              :class="[' flex w-full', visibility.second ? 'text-flame' : 'text-jet']"
            >
              <span>
                Resend Verification Code
              </span>
            </Button>

            <p>
              *Make sure you input the OTP correctly. Check your spam folder if
              you can’t find the verification email in your inbox. Otherwise,
              click on
              <span class="text-flame">“Resend Verification Code” </span> above
              to get another cornfirmation code.
            </p>
          </div>
        </div>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import OtpInput from "@bachdgvn/vue-otp-input";
import RightSideBar from "@/components/RightSideBar";
import Divider from "@/components/divider";
import Button from "@/components/Button";
import RadioButton from "@/components/RadioButton";
import Checkbox from "@/components/Checkbox";

export default {
  components: {
    RightSideBar,
    Divider,
    CText,
    Button,
    "v-otp-input": OtpInput,
    RadioButton,
    Checkbox
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean
    }
  },
  data() {
    return {
      disableBtn: false,
      disableAction: false,
      optionValue: this.status,
      password: "",
      passwordText: "password",
      encryptPassword: false,
      loading: false,
      setUp: false,
      resendLoader: false,
      setUpLabel: "2FA disabled for all users within the organization",
      twoFADisabled: false,
      bgButton: "bg-fadeFlame",
      enforceData: true,
      response: "",
      responseBg: "",
      success: false,
      save2FABtn: false,
      visibility: {
        first: true,
        second: false,
        third: false
      },
      options: [
        { name: "Enable", radioName: "2fa", value: true },
        { name: "Disable", radioName: "2fa", value: false }
      ],
      error: {
        first: false,
        second: false,
        third: false
      },
      otpInput: "",
      selectedItems: [],
      users: [
        {
          id: "1",
          title: "Esther Klinsman",
          subTitle: "CHIEF OPERATIONS OFFICER ",
          designationRole: "• ADMIN",
          photo: null,
          selected: false
        },
        {
          title: "King Ladipo",
          id: "2",
          subTitle: "CHIEF FINANCIAL OFFICER ",
          designationRole: "• Guest",
          photo: null,
          selected: false
        }
      ]
    };
  },
  watch: {
    optionValue(value) {
      if (value === "true") {
        this.visibility.third = true;
        this.resendCode();
      } else {
        this.visibility.third = false;
        this.bgButton = "bg-flame";
        this.save2FABtn = true;
      }
    },
    responseBg() {
      setTimeout(() => {
        this.response = "";
        if (this.success) {
          this.$emit("statusInfo", this.optionValue);
          this.closeModal();
        }
      }, 7000);
    },
    encryptPassword() {
      if (this.passwordText === "password") {
        this.passwordText = "";
      } else {
        this.passwordText = "password";
      }
    }
  },
  methods: {
    checkPassword() {
      this.error.first = false;
      this.loading = true;
      this.$_checkPassword({
        userId: this.$AuthUser.id,
        password: this.password
      })
        .then(() => {
          this.loading = false;
          this.visibility.first = false;
          this.visibility.second = true;
        })
        .catch(() => {
          this.loading = false;
          this.error.first = true;
        });
    },

    resendCode() {
      this.resendLoader = true;
      this.$_sendAdminTwoFactorCode({
        password: this.password,
        userId: this.$AuthUser.id
      })
        .then(() => {
          this.resendLoader = false;
          this.visibility.third = true;
        })
        .catch(() => {
          this.resendLoader = false;
          this.error.second = true;
        });
    },

    handleOnComplete(value) {
      this.otpInput = value;
      this.save2FABtn = true;
      this.bgButton = "bg-flame";
    },

    get2FAStatus() {
      this.$_getTwoFactorStatus()
        .then(result => {
          this.setUp = result.data.isSetup;
          this.optionValue = this.setUp;
          this.enforceData = result.data.isSetup;
          this.twoFADisabled = !result.data.isSetup;
        })
        .catch(err => {
          throw new Error(err);
        });
    },

    save2FA() {
      this.disableBtn = true;
      if (this.optionValue === "true") {
        const payload = {
          userId: this.$AuthUser.id,
          token: this.otpInput,
          twoFAEnabled: this.optionValue
        };
        this.$_twoFactorSetUp(payload)
          .then(result => {
            this.$toasted.success(result.data.msg, { duration: 5000 });
            this.disableBtn = false;
            this.closeModal();
            this.get2FAStatus();
          })
          .catch(() => {
            this.$toasted.error("Error in setting up your 2FA", {
              duration: 5000
            });
            this.disableBtn = false;
          });
      } else {
        const payload = {
          userId: this.$AuthUser.id,
          password: this.password
        };
        this.$_disableTwoFactor(payload)
          .then(result => {
            this.success = result.data.success;
            if (this.success) {
              this.$toasted.success(result.data.message, { duration: 5000 });
              this.closeModal();
              this.disableBtn = false;
            } else {
              this.$toasted.error(result.data.message, { duration: 5000 });
              this.disableBtn = false;
            }
            this.get2FAStatus();
          })
          .catch(err => {
            throw new Error(err);
          });
      }
    },

    async permitAction() {
      try {
        await this.$handlePrivilege("security", "accessTwo-FactorAuthenticationSettings");
        this.disableAction = false;
      } catch (error) {
        this.disableAction = true;
      }
    },


    closeModal() {
      this.bgButton = "bg-fadeFlame";
      this.visibility.first = true;
      this.visibility.second = false;
      this.visibility.third = false;
      this.error.first = false;
      this.error.second = false;
      this.error.third = false;
      this.password = "";
      this.response = "";
      this.responseBg = "";
      this.disableBtn = false;
      this.success = false;
      this.loading = false;
      this.encryptPassword = false;
      this.save2FABtn = false;
      this.$emit("close");
    }
  },
  mounted() {
    this.get2FAStatus();
    this.permitAction();
  }
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  text-align: center;
  outline: none;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bg-fadeFlame {
  background-color: rgba(241, 90, 41, 0.5);
}
</style>
